import Typed from "typed.js";
import $ from "cash-dom";

function getStrings() {
  var stringsLandscape = [
      "is an Art Director specializing <br>in Brand and UI/UX Design.",
      "is also a Front-End Developer <br>working in HTML/PHP, CSS <br>and JavaScript.",
      "is based in Offenbach, Germany, <br>the closest thing to Kreuzberg <br>Frankfurt has to offer.",
      "is available for freelance work <br>and looking for&shy;ward to hearing <br>about your project.",
      "is eventually going to launch <br>a proper website but he’s been <br>telling people that since 2014.",
      "is starting to feel increasingly <br>awk&shy;ward referring to himself <br>in the third person.",
      'is running out of topics and <br>ready for you to <a href="mailto:mail@timjuedemann.de">steer the <br>conversation.</a>',
    ],
    stringsPortrait = [
      "is an Art Director <br>specializing in <br>Brand and UI/UX <br>Design.",
      "is also a Front-End <br>Developer working <br>in HTML/PHP, CSS <br>and JavaScript.",
      "is based in Offen-<br>bach, Germany, <br>the closest thing <br>to Kreuzberg <br>Frankfurt has to <br>offer.",
      "is available for <br>freelance work and looking for&shy;ward to hearing about your project.",
      "is eventually going <br>to launch a proper <br>website but he’s <br>been telling people <br>that since 2014.",
      "is starting to feel increasingly awk&shy;ward referring to <br>himself in the third <br>person.",
      'is running out of <br>topics and ready <br>for you to <a href="mailto:mail@timjuedemann.de">steer the <br>conversation.</a>',
    ],
    strings = window.innerWidth > window.innerHeight ? stringsLandscape : stringsPortrait;

  return strings;
}

var typer;

$(document).ready(function () {
  if ($(".typed-output")[0]) {
    typer = new Typed(".typed-output", {
      strings: getStrings(),
      contentType: "html",
      smartBackspace: true,
      backDelay: 2000,
      typeSpeed: 40,
      fadeOut: true,
      fadeOutDelay: 100,
      showCursor: true,
      cursorChar: "",
      loop: false,
    });
  }

  $("a, .link")
    .on("mouseenter", function () {
      $(this).addClass("hover");
    })
    .on("mouseleave", function () {
      $(this).removeClass("hover");
    });

  $("[href]:not([href*=mailto])").on("click", function (e) {
    e.preventDefault();
    var el = this;
    $("html").removeClass("site-loaded");
    $("#preloader").on("transitionend", function () {
      window.location = $(el).attr("href");
    });
  });

  $(".obfuscate").text("mail@timjuedemann.de".split("").reverse().join("")).css({
    unicodeBidi: "bidi-override",
    direction: "rtl",
  });

  window.addEventListener("copy", function (e) {
    var selection = document.getSelection();
    e.preventDefault();
    e.clipboardData.setData("text/plain", selection.toString().replace("ed.nnamedeujmit@liam", "mail@timjuedemann.de"));
    console.log("copied");
  });

  $(".contact").on("click touch", function (e) {
    e.preventDefault();
    location.href = "mailto:mail@timjuedemann.de";
  });
});

window.onload = function (e) {
  $("html").addClass("site-loaded");
};

window.onpageshow = function (event) {
  if (event.persisted) {
    window.location.reload();
  }
};

window.onresize = function () {
  if ($(".typed-output")[0]) {
    typer.destroy();
    typer = new Typed(".typed-output", {
      strings: getStrings(),
      contentType: "html",
      smartBackspace: true,
      backDelay: 2000,
      typeSpeed: 40,
      fadeOut: true,
      fadeOutDelay: 100,
      showCursor: true,
      cursorChar: "",
      loop: false,
    });
  }
};
